import docReady from 'doc-ready';
import {initContainers} from '../util/init'
import {updateLinkState} from '../util/link_state'
import navigation from './navigation'
import {isMobileSite} from './constants'
import Cookies from 'js-cookie'
import {splitTitle, moveCursor} from './tools'

const bannerTitleInit = (wrapperEl, transition) => {
  const el = wrapperEl.querySelector('h1')
  return splitTitle(el, wrapperEl, el.getAttribute('data-gap'), el.getAttribute('data-cut'), el.getAttribute('data-home'), transition, el.getAttribute('data-show'))
}

const bannerInit = () => {
  const html = document.documentElement
  const titleWrapEl = html.querySelector('.title-banner-wrap')
  const firstLoad = Cookies.get('jamiemclellan-loaded') || false
  const splitTitleObj = bannerTitleInit(titleWrapEl, firstLoad)

  splitTitleObj.loaded.then(() => {
    html.classList.add('state-banner-loaded')
  })

  window.splitTitle = splitTitleObj

  return splitTitleObj
}

function initMenu () {
  const html = document.documentElement
  const burgerEl = html.querySelector('button.hamburger')
  const cursorEl = html.querySelector('#cursor')
  let menuVisible = false

  const toggle = (visible = null) => {
    if (visible === null) {
      menuVisible = !menuVisible
    } else {
      menuVisible = visible
    }
    document.body.classList[menuVisible ? 'add' : 'remove']('state-menu-visible')
    burgerEl.classList[menuVisible ? 'add' : 'remove']('is-active')
    html.classList[menuVisible ? 'add' : 'remove']('state-menu-visible')
  }

  burgerEl.addEventListener('click', e => {
    e.preventDefault()
    toggle()
  })

  // show for dev convenience
  if (window.location.search === '?showmenu') {
    document.body.classList.add('state-menu-visible')
    menuVisible = true
  }

  document.body.addEventListener('mousemove', (e) => {
    moveCursor(cursorEl, [e.x, e.y])
  })

  return {
    toggle: toggle
  }
}

function setup () {
  const menu = initMenu()

  navigation(initContent, menu)

  // window.onpopstate = function(event) {
  //   window.location.reload()
  //   return false
  // }

  window.addEventListener("orientationchange", () => {
    if (window.splitTitle) {
      let ww = window.orientation == 0 ? screen.width : screen.height
      let wh = window.orientation == 0 ? screen.height : screen.width
      window.splitTitle.orientation(ww, wh)
    }
  })

  loadFont()

  if (window.location.pathname == '/') {
    Cookies.set('jamiemclellan-loaded', true)
  } else {
    Cookies.remove('jamiemclellan-loaded')
  }

  const banner = bannerInit()
}

const loadFont = () => {
  const cssEl = document.getElementById('type-fugue')
  if (cssEl) {
    const html = document.documentElement
    const body = document.body
    const img = document.createElement('img')

    cssEl.setAttribute('href', cssEl.getAttribute('data-href'))

    img.onerror = () => {
      html.classList.add('state-font-loaded')
      body.removeChild(img)
    }

    body.appendChild(img)
    img.setAttribute('src', cssEl.getAttribute('data-href'))
  }
}


function initContent (container) {
  if (container) {
    // initialises newly loaded content
    const current = container.children

    initContainers(current)
  }
  updateLinkState(document.querySelectorAll('#header, #footer, #content'))
}

docReady(() => {
  setup()
  initContent(document.querySelector('#content'))

  document.body.classList.add('state-initialised')
})
