// polyfill for native Promise objects - required by whatwg-fetch
// https://babeljs.io/docs/usage/polyfill/
import 'babel-polyfill'

// polyfill for fetch api - https://github.com/github/fetch
import 'whatwg-fetch'

// polyfill for RequestAnimationFrame
import 'raf/polyfill'

// polyfill native browser smooth scrolling
import smoothscroll from 'smoothscroll-polyfill'
smoothscroll.polyfill()

// polyfill Element.matches - see
// https://developer.mozilla.org/en-US/docs/Web/API/Element/matches#Polyfill
if (!window.Element.prototype.matches) {
  window.Element.prototype.matches =
      window.Element.prototype.msMatchesSelector ||
      window.Element.prototype.webkitMatchesSelector
}

// hackyfill for firefox and maybe other browsers so we can use
// document.QuerySelectorAll('...').forEach(...)
// TODO don't leave this here, need a wrapper for forEach
// NOTE leaving here just in case, but use Array(...nodelist).forEach instead
if (window.NodeList.prototype.forEach === undefined) {
  window.NodeList.prototype.forEach = window.Array.prototype.forEach
}

// polyfill for HTMLElement.remove()
// from:https://github.com/jserz/js_piece/blob/master/DOM/ChildNode/remove()/remove().md
(function (arr) {
  arr.forEach(function (item) {
    if (item.hasOwnProperty('remove')) {
      return
    }
    Object.defineProperty(item, 'remove', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function remove () {
        if (this.parentNode !== null) {
          this.parentNode.removeChild(this)
        }
      }
    })
  })
})([Element.prototype, CharacterData.prototype, DocumentType.prototype])
