import register from '../util/init'
import {get} from '../util/fetch'
import {carousel} from './featureCarousel'
import {pushState} from '../util/history'
import {checkVisible, moveCursor, splitTitle} from './tools'
import {iosInnerHeight, isMobileSite, isLandscapeSite, PAGE_TRANSITION} from './constants'
import {windowScroll} from '../util/scroll_resize'
import InfScr from './infscr'
import offset from 'document-offset'
import $ from 'jquery'
import Cookies from 'js-cookie'

const loadPageTransition = (pageClass) => {
  setTimeout(() => {
    document.documentElement.classList.add(pageClass, 'state-site-loaded', 'state-title-show-main', 'state-title-show-sub')

    setTimeout(() => {
      document.documentElement.classList.add('state-title-show-sub-in')
    }, PAGE_TRANSITION * 1.5)
  }, PAGE_TRANSITION)
}

const pageTitleInit = (titleEl, gap, cutBias, home, transition) => {
  titleEl.style.visibility = 'visible'
  return splitTitle(titleEl.querySelector('h1, .image-wrap'), null, gap, cutBias, home, transition)
}

function portfolioProjectDetail (container) {
  let isMobile = isMobileSite()
  let isLandscape = isLandscapeSite()
  const html = document.documentElement
  const projectWrap = container.querySelector('.project-wrap')
  const carouselWrap = container.querySelector('.carousel-wrap')
  const galleryOpts = {fullscreen: true}
  if (isMobile) {
    galleryOpts['setGallerySize'] = false
    galleryOpts['adaptiveHeight'] = false
    galleryOpts['pageDots'] = false
    galleryOpts['dragThreshold'] = 10
  }
  const carouselObj = carousel(carouselWrap, galleryOpts)
  const cursorEl = html.querySelector('#cursor')
  let carouselItems = carouselWrap.querySelectorAll('.carousel-item .image-wrap')
  let carouselItemW = ''
  let removeI = -1
  let isFullscreen = false
  let firstSelect = true
  let cursorX = 0
  let ww = window.innerWidth
  let wh = iosInnerHeight()

  const init = () => {
    const imageList = carouselWrap.querySelectorAll('.carousel-item .image-wrap')
    loadCursorInfo(false, projectWrap.getAttribute('data-prev-title'))

    imageList.forEach((imageEl) => {
      imageEl.addEventListener('mouseenter', () => {
        html.classList.add('state-carousel-hover')
      })
      imageEl.addEventListener('mouseleave', () => {
        html.classList.remove('state-carousel-hover')
      })
    })

    window.scroll({
      top: 0,
      behavior: 'smooth'
    })
  }

  const resizeCarouselItem = (el, h) => {
    let img = el.querySelector('.image img')

    if (isMobile && el.classList.contains('has-mobile') && !isLandscape) {
      img = el.querySelector('.mobile-only .image img')
    }
    const imgW = parseInt(img.getAttribute('width'))
    const imgH = parseInt(img.getAttribute('height'))
    const newH = imgH / imgW * ww
    const w = imgW / imgH * h

    if (isMobile) {
      if (isLandscape) {
        el.style.width = `${imgW / imgH * (wh / 36 * 32)}px`
      } else {
        el.style.width = `${imgW / imgH * (ww / 36 * 32)}px`
      }
    } else if (imgW > imgH && isFullscreen) {
      el.style.width = (newH >= wh) ? '100%' : `${w}px`
    } else {
      el.style.width = `${w}px`
    }
  }

  const loadCursorInfo = (next, title) => {
    const textEl = cursorEl.querySelector('.cursor-text')

    while (textEl.lastChild) {
      textEl.removeChild(textEl.lastChild)
    }
    html.classList.remove('state-cursor-text-left', 'state-cursor-text-right')

    if (title) {
      const p1 = document.createElement('p')
      const p2 = document.createElement('p')
      p1.appendChild(document.createTextNode(next ? 'Next' : 'Previous'))
      p2.appendChild(document.createTextNode(title))
      textEl.appendChild(p1)
      textEl.appendChild(p2)
      html.classList.add(next ? 'state-cursor-text-right' : 'state-cursor-text-left')
    }
  }

  const clearSlides = () => {
    if (carouselObj.flkty) {
      const i = carouselObj.flkty.selectedIndex
      const slides = []

      carouselObj.flkty.cells.forEach((cell, j) => {
        if (i != j) slides.push(cell.element)
      })

      carouselObj.flkty.remove(slides)
    }
  }

  const addSlides = (newProjectEl, next) => {
    clearSlides()

    if (carouselObj.flkty) {
      const slides = Array.from(newProjectEl.querySelectorAll('.carousel-wrap .carousel-item'))

      if (next) {
        if (isFullscreen) {
          if (slides.length > 1) {
            carouselObj.flkty.insert(slides.slice(1, slides.length), 1)
          }

          carouselObj.flkty.insert(slides[0], 0)
        } else {
          carouselObj.flkty.insert(slides, 1)
        }

        setTimeout(() => {
          carouselObj.flkty.next()
          removeI = isFullscreen ? 1 : 0
        }, PAGE_TRANSITION)
      } else {
        if (isFullscreen && slides.length > 1) {
          if (slides.length > 2) {
            carouselObj.flkty.insert(slides.slice(2, slides.length), 1)
          }

          carouselObj.flkty.insert(slides.slice(0, 2), 0)
        } else {
          if (slides.length > 1) {
            carouselObj.flkty.insert(slides.slice(1, slides.length), 1)
          }

          carouselObj.flkty.insert(slides[0], 0)
        }

        setTimeout(() => {
          carouselObj.flkty.previous()
          removeI = (isFullscreen && slides.length > 1) ? 2 : 1
        }, PAGE_TRANSITION)
      }
    }
  }

  const loadProject = (url, next) => {
    const changeTitle = (newProjectEl, newHtml) => {
      const titleEl = html.querySelector('#header .page-title-wrap')
      titleEl.innerHTML = newHtml.querySelector('#header .page-title-wrap').innerHTML

      setTimeout(() => {
        if (isMobile) newHtml.querySelector('.title-banner-wrap h1').setAttribute('data-gap', 150)
        window.splitTitle.reload(newHtml.querySelector('.title-banner-wrap'))
      }, 250)
    }
    const changeData = (newProjectEl) => {
      const wrapEl = newProjectEl.querySelector('.project-wrap')

      projectWrap.setAttribute('data-prev-url', wrapEl.getAttribute('data-prev-url') || '')
      projectWrap.setAttribute('data-prev-title', wrapEl.getAttribute('data-prev-title') || '')
      projectWrap.setAttribute('data-next-url', wrapEl.getAttribute('data-next-url') || '')
      projectWrap.setAttribute('data-next-title', wrapEl.getAttribute('data-next-title') || '')
      projectWrap.querySelector('.mobile-buttons button.carousel-next').innerHTML = wrapEl.querySelector('.mobile-buttons button.carousel-next').innerHTML
    }

    carouselWrap.classList.add('disabled')
    let scroller = isMobile ? window : window
    scroller.scroll({top: 0, behavior: 'smooth'})
    html.classList.add('state-page-loading', 'state-page-transition')

    get(url).then((response) => {
      return response.text()
    }).then((response) => {
      let div = document.createElement('div')
      div.innerHTML = response

      const newProjectEl = div.querySelector('article.portfolio-project-detail')

      if (newProjectEl) {
        let imageH = wh

        if (isMobile) {
          imageH = wh - (isLandscape ? 94 : 200)
          newProjectEl.querySelectorAll('.carousel-item .description-wrap').forEach((el) => {
            if (isLandscape) {
              let tempH = el.querySelector('.description-inner').offsetHeight * 1.275

              el.style.width = `${wh > tempH ? wh : tempH}px`
              el.style.minHeight = ''
              el.style.maxHeight = `${wh}px`
              el.style.height = `${tempH == 0 ? wh : tempH}px`
            } else {
              el.style.width = ''
              el.style.minHeight = `${imageH}px`
              el.style.maxHeight = ''
              el.style.height = ''
           }
          })
        } else if (isFullscreen) {
          imageH = wh
        } else {
          imageH = wh - (2 * offset(projectWrap).top)
        }
        newProjectEl.querySelectorAll('.carousel-wrap .carousel-item .image-wrap').forEach((el) => {
          resizeCarouselItem(el, imageH)
        })
        changeTitle(newProjectEl, div)
        addSlides(newProjectEl, next)
        loadCursorInfo(false, projectWrap.getAttribute('data-prev-title'))
        changeData(newProjectEl)
        carouselItems = carouselWrap.querySelectorAll('.carousel-item .image-wrap')
        document.title = div.querySelector('title').textContent
        pushState(url)
        init()
        if (carouselObj.flkty) carouselObj.flkty.resize()
      }
    })
  }

  const exitFullscreen = () => {
    isFullscreen = false
    carouselObj.flkty.exitFullscreen()
    resizeCarousel(window.innerWidth, iosInnerHeight())
  }

  const toggleFullscreen = (exit) => {
    if (isMobile || exit || isFullscreen) {
      exitFullscreen()
    } else {
      isFullscreen = true
      carouselObj.flkty.viewFullscreen()
      resizeCarousel(window.innerWidth, iosInnerHeight())
    }

  }

  const moveSlide = (i, count, x) => {
    const goNext = () => {
      if (i < count - 1) {
        carouselObj.flkty.next()
      } else {
        const url = projectWrap.getAttribute('data-next-url')

        if (url) {
          loadProject(url, true)
        }
      }
    }
    const goPrevious = () => {
      if (i > 0) {
        carouselObj.flkty.previous()
      } else {
        const url = projectWrap.getAttribute('data-prev-url')

        if (url) {
          loadProject(url, false)
        }
      }
    }
    if (isFullscreen || carouselObj.flkty.selectedIndex > 0) {
      if (x > 66.66) {
        goNext()
      } else if (x < 33.33) {
        goPrevious()
      } else {
        toggleFullscreen()
      }
    } else {
      if (x > 50) {
        goNext()
      } else {
        goPrevious()
      }
    }
  }

  const processSlide = (i, count) => {
    if (i == 0) {
      loadCursorInfo(false, projectWrap.getAttribute('data-prev-title'))
    } else if (i >= count - 1) {
      loadCursorInfo(true, projectWrap.getAttribute('data-next-title'))
    } else {
      loadCursorInfo(true, null)
    }
  }

  init()

  let projectTimer

  projectWrap.addEventListener('mouseenter', (e) => {
    clearTimeout(projectTimer)
    html.classList.remove('state-cursor-visible')
    html.classList.remove('state-project-hover', 'state-cursor-link', 'state-cursor-right', 'state-cursor-left', 'state-project-hover-off', 'state-project-hover-on')
    html.classList.add('state-project-hover-on')
    html.classList.add('state-cursor-visible', 'state-cursor-right')

    projectTimer = setTimeout(() => {
      html.classList.remove('state-project-hover-on')
    }, 250)
  })
  projectWrap.addEventListener('mouseleave', (e) => {
    clearTimeout(projectTimer)
    html.classList.add('state-project-hover-off')

    projectTimer = setTimeout(() => {
      html.classList.remove('state-cursor-visible')
      html.classList.remove('state-cursor-link', 'state-project-hover-off', 'state-project-hover-on', 'state-cursor-left', 'state-cursor-right')
    }, 250)
  })
  carouselWrap.querySelectorAll('.description-wrap a').forEach((el) => {
    el.addEventListener('mouseenter', (e) => {
      html.classList.remove('state-cursor-visible', 'state-cursor-right')
    })
    el.addEventListener('mouseleave', (e) => {
      html.classList.add('state-cursor-visible', 'state-cursor-right')
    })
  })
  projectWrap.addEventListener('mousemove', (e) => {
    cursorX = e.x
    let x = 100 / carouselWrap.offsetWidth * e.x

    if (isFullscreen || carouselObj.flkty.selectedIndex > 0) {
      if (x > 66.66) {
        html.classList.add('state-cursor-right')
        html.classList.remove('state-cursor-left', 'state-cursor-link')
      } else if (x < 33.33) {
        html.classList.add('state-cursor-left')
        html.classList.remove('state-cursor-right', 'state-cursor-link')
      } else {
        html.classList.add('state-cursor-link')
        html.classList.remove('state-cursor-left', 'state-cursor-right')
      }
    } else {
      if (x > 50) {
        html.classList.add('state-cursor-right')
        html.classList.remove('state-cursor-left', 'state-cursor-link')
      } else {
        html.classList.add('state-cursor-left')
        html.classList.remove('state-cursor-right', 'state-cursor-link')
      }
    }
  })
  if (carouselObj.flkty) {
    if (isMobile) {
      carouselWrap.querySelectorAll('.mobile-buttons button').forEach((el) => {
        el.addEventListener('click', (e) => {
          let x = 0
          if (el.classList.contains('carousel-next')) {
            x = 100
          }

          if (!html.classList.contains('state-page-loading')) {
            moveSlide(carouselObj.flkty.selectedIndex, carouselObj.flkty.slides.length, x)
          }
        })
      })
    } else {
      carouselObj.flkty.on('dragMove', (e) => {
        moveCursor(cursorEl, [e.x, e.y])
      })
      carouselObj.flkty.on('staticClick', (e) => {
        let x = 100 / carouselWrap.offsetWidth * e.x

        moveSlide(carouselObj.flkty.selectedIndex, carouselObj.flkty.slides.length, x)
      })
    }

    carouselObj.flkty.on('settle', () => {
      if (removeI >= 0) {
        carouselObj.flkty.remove(carouselObj.flkty.cells[removeI].element)
        carouselObj.flkty.resize()
        removeI = -1
        carouselWrap.classList.remove('disabled')
        html.classList.remove('state-page-loading')
        setTimeout(() => {
          html.classList.remove('state-page-transition')
        }, 50)
      }
    })
    carouselObj.flkty.on('select', (e) => {
      processSlide(carouselObj.flkty.selectedIndex, carouselObj.flkty.slides.length)
      let scroller = isMobile ? container : window
      scroller.scroll({top: 0, behavior: 'smooth'})

      if (carouselObj.flkty.selectedIndex == 0) {
        html.classList.add('state-carousel-first')
      } else {
        html.classList.remove('state-carousel-first')
      }
      if (firstSelect) {
        firstSelect = false
      } else {
        if (carouselObj.flkty.selectedElement.classList.contains('text-cell')) {
          html.classList.remove('state-carousel-viewing')
        } else {
          html.classList.add('state-carousel-viewing')
        }
      }
    })
  }

  const resizeCarousel = (...args) => {
    const resizeItems = (h) => {
      carouselItems.forEach((el) => {
        resizeCarouselItem(el, h)
      })
      if (carouselObj.flkty) carouselObj.flkty.resize()
    }

    if (isMobile) {
      const imageH = isLandscape ? 94 : 200
      carouselWrap.querySelectorAll('.carousel-item .description-wrap').forEach((el) => {
        if (isLandscape) {
          let tempH = el.querySelector('.description-inner').offsetHeight * 1.275

          el.style.width = `${wh > tempH ? wh : tempH}px`
          el.style.minHeight = ''
          el.style.maxHeight = `${wh}px`
          el.style.height = `${tempH}px`
        } else {
          el.style.width = ''
          el.style.minHeight = `${args[1] - imageH}px`
          el.style.maxHeight = ''
          el.style.height = ''
        }
      })
      resizeItems(args[1] - imageH)
    } else if (!isFullscreen) {
      resizeItems(args[1] - (offset(projectWrap).top * 2))
    } else {
      resizeItems(args[1])
    }
    setTimeout(() => {
      carouselWrap.classList.add('initialised-size')
    }, isMobile ? 1750 : 0)
  }

  window.splitTitle.loaded.then(() => {
    loadPageTransition('state-project-loaded')
  })

  html.classList.add('state-carousel-first')

  return {
    resize: (...args) => {
      ww = args[0]
      wh = args[1]
      isMobile = isMobileSite()
      isLandscape = isLandscapeSite()

      setTimeout(() => {
        resizeCarousel(...args)
        window.splitTitle.resize(...args)
      }, 25)
    },
    teardown: () => {
      window.splitTitle.destroy()
      document.documentElement.classList.remove('state-cursor-left', 'state-cursor-right', 'state-cursor-link', 'state-cursor-visible', 'state-page-transition', 'state-page-loading', 'state-carousel-viewing', 'state-carousel-hover', 'state-project-loaded', 'state-carousel-first', 'state-cursor-text-left', 'state-cursor-text-right')
    }
  }
}
register('.portfolio-project-detail', portfolioProjectDetail)

function jamieMcLellanInfo (container) {
  loadPageTransition('state-info-loaded')

  return {
    resize: (...args) => {
      window.splitTitle.resize(...args)
    },
    teardown: () => {
      document.documentElement.classList.remove('state-info-loaded')
    }
  }
}
register('.jamiemclellan-info', jamieMcLellanInfo)


function jamieMcLellan404 (container) {
  loadPageTransition('state-info-loaded')

  return {
    resize: (...args) => {
      window.splitTitle.resize(...args)
    },
    teardown: () => {
      document.documentElement.classList.remove('state-info-loaded')
    }
  }
}
register('.jamiemclellan-404', jamieMcLellan404)

const loadImages = (images, loadAll, callback) => {
  const loadImg = (image) => {
    if (!image.classList.contains('loading') && !image.classList.contains('loaded')) {
      const img = image.querySelector('img')

      img.setAttribute('src', img.getAttribute('data-src'))
      if (img.getAttribute('data-srcset')) {
        img.setAttribute('srcset', img.getAttribute('data-srcset'))
      }
      if (img.getAttribute('data-sizes')) {
        img.setAttribute('sizes', img.getAttribute('data-sizes'))
      }

      img.onload = () => {
        console.log('image loaded')
        image.classList.add('loaded')
        image.classList.remove('loading')

        if (callback) callback()
      }

      image.classList.add('loading')
    }
  }

  if (loadAll) {
    images.forEach(loadImg)
  } else {
    loadImg(images[0])
  }
}

function portfolioProjects (container) {
  const html = document.documentElement
  const projectListWrap = container.querySelector('.project-list')
  const cursorEl = html.querySelector('#cursor')
  let projectList = projectListWrap.querySelectorAll('.project-listing')
  let rotateInterval
  let isMobile = isMobileSite()

  const loadProjectImages = (projectEl, scrT) => {
    let images
    let loadAll = false
    let load = false
    const offsetTop = offset(projectEl).top

    if (isMobile) {
      images = Array.from([projectEl.querySelector('.image.lazy-load')])

      if (offsetTop <= scrT + 2500) {
        load = true
      }
    } else {
      images = projectEl.querySelectorAll('.image.lazy-load')

      if (checkVisible(projectEl, 'visible', scrT)) {
        loadAll = true
        load = true
      } else if (offsetTop <= scrT || offset(projectEl).top < (scrT + 2500)) {
        load = true
      }
    }

    if (load) loadImages(images, loadAll)
  }

  const rotateImages = (projectEl) => {
    const imageList = projectEl.querySelectorAll('.image-wrap .image')
    let i = 1

    if (imageList.length == 0) return

    const next = () => {
      const activeImages = Array.prototype.slice.call(imageList).filter((el) => { return el.classList.contains('active') })
      imageList[i].classList.add('active')
      i = (i + 1) % imageList.length

      activeImages.forEach((el) => {
        el.classList.remove('active')
      })
    }

    imageList[0].classList.add('active')

    if (imageList.length > 1) {
      rotateInterval = setInterval(() => {
        next()
      }, 1000)
    }
  }

  const resetImages = (projectEl) => {
    const imageList = projectEl.querySelectorAll('.image-wrap .image')
    clearTimeout(rotateInterval)
    clearInterval(rotateInterval)
    imageList.forEach((el) => {
      el.classList.remove('active')
    })
    imageList[0].classList.add('active')
  }

  let projectTimer

  const projectListingInit = (projectEl) => {
    if (projectEl.classList.contains('project-listing-loaded')) return


    if (!isMobile) {
      projectEl.addEventListener('mouseenter', (e) => {
        clearTimeout(projectTimer)
        html.classList.remove('state-cursor-visible')
        html.classList.remove('state-project-hover', 'state-cursor-link', 'state-project-hover-off', 'state-project-hover-on')
        html.classList.add('state-project-hover-on')
        html.classList.add('state-project-hover', 'state-cursor-link', 'state-project-hover-on')
        html.classList.add('state-cursor-visible')

        projectTimer = setTimeout(() => {
          html.classList.add('state-project-hover')
          html.classList.remove('state-project-hover-on')
          projectEl.classList.add('project-active')
          rotateImages(projectEl)
        }, 250)
      })
      projectEl.addEventListener('mouseleave', (e) => {
        clearTimeout(projectTimer)
        html.classList.add('state-project-hover-off')
        projectEl.classList.remove('project-active')
        resetImages(projectEl)

        projectTimer = setTimeout(() => {
          html.classList.remove('state-cursor-visible')
          html.classList.remove('state-project-hover', 'state-cursor-link', 'state-project-hover-off', 'state-project-hover-on')
        }, 250)
      })
    }

    projectEl.classList.add('project-listing-loaded')
  }

  const bannerTitleScroll = (...args) => {
    if (isMobile) {
      projectList.forEach((projectEl) => {
        loadProjectImages(projectEl, args[0])
      })
    }
  }

  projectList.forEach(projectListingInit)

  const scrollState = () => {
    if (projectListWrap.querySelector('.next')) {
      document.body.classList.add('infinite-scrolling')
    } else {
      document.body.classList.remove('infinite-scrolling')
    }
  }

  const infscr = InfScr($(projectListWrap), {
    contentSelector: '.project-listing, .no-results',
    nextSelector: '.next',
    autoOffset: 2500,
    beforeLoad: function () {
      projectListWrap.classList.add('loading-more')
    },
    onLoad: function (newContent, container) {
      newContent.each((i, el) => {projectListingInit(el)})
      projectList = projectListWrap.querySelectorAll('.project-listing')
      projectListWrap.classList.remove('loading-more')
      scrollState()
    }
  })

  let wScroll = windowScroll()
  bannerTitleScroll(wScroll.top, wScroll.left)

  window.splitTitle.loaded.then(() => {
    loadPageTransition('state-projects-loaded')
    setTimeout(() => {
      window.splitTitle.transition()
    }, PAGE_TRANSITION)
  })

  return {
    resize: (...args) => {
      isMobile = isMobileSite()
      infscr.resize(...args)
      window.splitTitle.resize(...args)
    },
    scroll: (...args) => {
      bannerTitleScroll(...args)
      infscr.scroll(...args)

      if (!isMobile) {
        projectList.forEach((projectEl) => {
          loadProjectImages(projectEl, args[0])
        })
      }
    },
    teardown: () => {
      html.classList.remove('state-projects-loaded')
    }
  }
}
register('.portfolio-projects', portfolioProjects)
