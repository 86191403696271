import {iosInnerHeight, isMobileSite, WIN_WIDTH, WIN_HEIGHT, MAX_MOBILE} from './constants'


export const moveCursor = (el, point) => {
  el.style.transform = `translate3d(calc(${point[0]}px - 50%), calc(${point[1]}px - 50%), 0)`
}

export const checkVisible = (elm, evalType, scrT) => {
  evalType = evalType || "visible"

  var vpH = iosInnerHeight(), // Viewport Height
      y = elm.offsetTop,
      elementHeight = elm.offsetHeight

  if (evalType === "visible") return ((y < (vpH + scrT)) && (y > (scrT - elementHeight)))
  if (evalType === "above") return ((y < (vpH + scrT)))
}

const isHidden = (el) => {
  return (el.offsetParent === null)
}

class SplitTitle {
  constructor (el, wrapper, gap, cut, home, vTransition, show) {
    const that = this
    this.html = document.documentElement
    this.wrapperEl = wrapper || el.parentNode
    this.elem1 = el
    this.elem2 = el.cloneNode(true)
    this.testElem = el.cloneNode(true)
    this.centerGap = parseInt(gap) || 350
    this.originalCenterGap = this.centerGap
    this.cutBias = parseFloat(cut) || 1 // 1 = center
    this.isHome = parseInt(home) == 1 ? true : false
    this.isTransition = vTransition || false
    this.mainW = this.wrapperEl.offsetWidth
    this.mainH = this.wrapperEl.offsetHeight
    this.fontLoaded = false
    this.isMobile = isMobileSite()
    this.isLandscape = window.orientation == 0 && this.isMobile ? false : true
    this.transitioned = 1
    this.windowSize = [window.innerWidth, iosInnerHeight()]
    this.show = parseInt(show) == 1 ? true : false

    if (this.isTransition) {
      this.transitioned = 0
    }

    let showMobile = el.getAttribute('data-mobile')

    if (this.isMobile) {
      if (showMobile) {
        this.show = parseInt(showMobile) == 1
      }
    }

    this.wrapperEl.classList.remove('loaded')

    this.wrapperEl.style.visibility = this.show ? 'visible' : ''

    this.wrapperEl.appendChild(this.elem2)
    this.wrapperEl.appendChild(this.testElem)

    this.promise = new Promise((resolve, reject) => {
      that.fontLoadedInt = setInterval(() => {
        if (that.html.classList.contains('state-font-loaded')) {
          clearInterval(that.fontLoadedInt)
          that.fontLoaded = true
          that.update(WIN_WIDTH, WIN_HEIGHT)
          resolve()
        }
      }, 50)
    })
  }

  resizeTitle() {
    const that = this
    if (isHidden(this.testElem)) return
    const targetWidth = this.mainW - this.centerGap

    if (this.elem1.classList.contains('image-wrap')) {
      this.elem1.style.width = `${targetWidth}px`
      this.elem1.style.height = 'auto'
      this.elem2.style.width = `${targetWidth}px`
      this.elem2.style.height = 'auto'
      this.elem1.addEventListener('load', () => {
        that.update()
      })
    } else {
      let startingSize = 10
      this.testElem.style.fontSize = `${startingSize}px`
      // climb in 10px increments until we get just over
      while(this.testElem.offsetWidth < targetWidth) {
        startingSize += 1
        this.testElem.style.fontSize = `${startingSize}px`
      }
      // then go back down one px at a time until we're just under
      while(this.testElem.offsetWidth >= targetWidth) {
        startingSize -= 1
        this.testElem.style.fontSize = `${startingSize}px`
      }

      startingSize += 1

      // that's our best
      this.elem2.style.fontSize = `${startingSize}px`
      this.elem1.style.fontSize = `${startingSize}px`
      this.testElem.style.fontSize = `${startingSize}px`
    }
  }

  position() {
    const that = this
    let mSpot = this.testElem.offsetWidth * 0.4703632887

    if (!this.isLandscape) {
      let left = Math.floor((this.mainH / 2) - (this.testElem.offsetHeight / 2))
      let top = this.testElem.offsetWidth + this.testElem.offsetHeight
      let logoRatio = 0.4703632887

      if (this.isHome) {
        let x1 = `${Math.ceil(-top + this.testElem.offsetWidth - mSpot)}`
        let x2 = `${Math.ceil(-this.testElem.offsetHeight + this.mainW - mSpot)}`
        this.elem1.style.transform = `translate3d(${left}px, ${x1}px, 0) rotate(90deg)`
        this.elem2.style.transform = `translate3d(${left}px, ${x2}px, 0) rotate(90deg)`
      } else {
        let x1 =  `${(-top + (this.mainW / 2) - (this.centerGap / 2)) * this.cutBias}`
        let x2 = `${(-this.testElem.offsetHeight + (this.mainW / 2) + (this.centerGap / 2)) * this.cutBias}`

        this.elem1.style.transform = `translate3d(${left}px, ${x1}px, 0) rotate(90deg)`
        this.elem2.style.transform = `translate3d(${left}px, ${x2}px, 0) rotate(90deg)`
      }
    } else {
      let w = (this.mainW + this.centerGap) / 2
      let h = Math.floor((this.mainH - this.testElem.offsetHeight) / 2)

      if (this.isHome) {
        let x1 = `${Math.ceil(-mSpot)}`
        let x2 = `${Math.ceil(this.mainW - mSpot)}`

        if (this.transitioned === 1) {
          this.elem1.style.transform = `translate3d(${x1}px, ${h}px, 0)`
          this.elem2.style.transform = `translate3d(${x2}px, ${h}px, 0)`
        } else {
          this.elem1.style.transform = `translate3d(${Math.floor((this.mainW / 2) - this.testElem.offsetWidth / 2)}px, ${h}px, 0)`
          this.elem2.style.transform = `translate3d(${Math.floor(((this.mainW / 2) - this.testElem.offsetWidth / 2) + this.centerGap + this.testElem.offsetWidth)}px, ${h}px, 0)`
        }
      } else {
        let x1 = Math.ceil(this.testElem.offsetWidth - (this.mainW - w))
        this.elem1.style.transform = `translate3d(-${x1}px, ${h}px, 0)`
        this.elem2.style.transform = `translate3d(${w}px, ${h}px, 0)`
      }
    }
  }

  update(...args) {
    const that = this
    if (!this.fontLoaded) return

    if (this.isMobile && this.isLandscape) this.centerGap = args[1] * .25

    if (!this.isLandscape) {
      this.mainW = args[1]
      this.mainH = args[0]
    } else {
      this.mainW = this.wrapperEl.offsetWidth
      this.mainH = this.wrapperEl.offsetHeight
      this.centerGap = this.originalCenterGap
    }

    this.resizeTitle()
    this.position()

    setTimeout(() => {
      that.wrapperEl.classList.remove('positioning')
      that.wrapperEl.classList.add('loaded')
    }, 500)
  }

  resize(ww, wh, force) {
    const that = this
    this.windowSize = [ww, wh]
    this.isMobile = isMobileSite()
    this.isLandscape = window.orientation == 0 && this.isMobile ? false : true

    if (force || !this.isMobile || ww > MAX_MOBILE) {
      this.wrapperEl.classList.add('positioning')
      that.update(that.windowSize[0], that.windowSize[1])
    }
  }

  doTransition() {
    this.transitioned = 1
    this.position()
  }

  destroy() {
    this.wrapperEl.classList.remove('loaded')
  }

  reload(el, wrapper, gap, cut, home, vTransition, show) {
    const dataMobile = el.getAttribute('data-mobile')
    if (isMobileSite()) {
      if (this.testElem.textContent == el.textContent && dataMobile && parseInt(dataMobile) == 1 && this.show) {
        return
      }
    } else {
      if (this.show && parseInt(show) == 1 && this.testElem.textContent == el.textContent) {
        return
      }
    }

    const that = this
    const newNode = el.cloneNode(true)

    while (this.wrapperEl.lastChild) {
      that.wrapperEl.removeChild(that.wrapperEl.lastChild)
    }
    this.wrapperEl.style.visibility = ''
    this.wrapperEl.appendChild(newNode)
    this.constructor(newNode, this.wrapperEl, gap, cut, home, vTransition, show)
  }
}

export const splitTitle = (el, wrapper, gap, cut, home, vTransition, show) => {
  const title = new SplitTitle(el, wrapper, gap, cut, home, vTransition, show)
  let resizeTimer

  return {
    reload: (wrapperEl) => {
      const el = wrapperEl.querySelector('h1')
      title.reload(el, wrapperEl, el.getAttribute('data-gap'), el.getAttribute('data-cut'), el.getAttribute('data-home'), false, el.getAttribute('data-show'))
    },
    loaded: title.promise,
    resize: (...args) => {
      title.resize(...args)
    },
    orientation: (...args) => {
      clearTimeout(resizeTimer)

      resizeTimer = setTimeout(() => {
        if (window.orientation == 0) {
          title.resize(args[0], args[1], true)
        } else {
          title.resize(args[1], args[0], true)
        }
      }, 50)
    },
    transition: () => {
      title.doTransition()
    },
    destroy: () => {
      title.destroy()
    }
  }
}
