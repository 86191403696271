import delegate from 'delegate'
import {
  getCurrentUrl,
  getTitle,
  getBody,
  after,
  isNumber,
  isLocalLink
} from '../util/ajax_util'
import offset from 'document-offset'
import {teardownContainers, initContainers} from '../util/init'

class JamieMcLellanAjaxLoader {
  constructor (initContent, menu) {
    this.contentEl = document.querySelector('#content')
    this.initContent = initContent
    this.menu = menu
    this.ns = 'jamiemclellan'
    this.history = window.Modernizr.history
    this.bind()

    // contentUrl tracks what's currently in #content
    this.contentUrl = window.location.pathname

    this.loading = false
  }
  toggleLoading (isLoading) {
    if (this.loading !== isLoading) {
      this.loading = isLoading
      document.documentElement.classList[this.loading ? 'add' : 'remove']('state-loading')
    }
  }
  pushState (url) {
    if (this.history) {
      // only push state if it's a new page load
      window.history.pushState({ns: this.ns}, null, url)
    }
  }
  load (url, scrollTarget, state) {
    /* scrollTarget defaults to 0 if the url doesn't contain a # fragment. */
    const currentPath = getCurrentUrl().split('#')[0]
    const urlAnchor = url.split('#')[1]
    const urlPath = url.split('#')[0]

    const changeState = () => {
      if (!state) {
        this.pushState(url)
      }
      this.onStateChange(url)
    }

    // scroll to local anchor if we're already on the page
    if (urlPath === currentPath && urlAnchor) {
      this.scrollToAnchor(urlAnchor)
      return
    }

    const transitionOut = () => {
      this.toggleLoading(true)

      document.documentElement.classList.remove('state-title-show-sub', 'state-title-show-sub')
      if (url == '/') document.documentElement.classList.remove('state-title-show-main')

      Array.from(this.contentEl.children).forEach((el) => {
        el.classList.add('transition-out')
      })

      document.documentElement.querySelector('header#header').classList.add('transition-out')

      setTimeout(() => {
        teardownContainers(this.contentEl.children)
        this.contentEl.innerHTML = ''
        document.documentElement.querySelector('header#header').classList.remove('transition-out')
      }, 2000)
    }

    let html

    const reveal = after(2, () => {
      const title = getTitle(html)
      const body = getBody(html)

      if (title) {
        document.title = title
      }

      this.menu.toggle(false)

      body.classList.add('state-initialised')

      document.body.setAttribute('class', body.getAttribute('class'))
      this.contentEl.innerHTML = body.querySelector('#content').innerHTML
      document.documentElement.querySelector('#header .page-title-wrap .text-wrap').innerHTML = body.querySelector('#header .page-title-wrap .text-wrap').innerHTML
      this.initContent(this.contentEl)
      this.contentUrl = url
      this.toggleLoading(false)

      window.splitTitle.reload(body.querySelector('.title-banner-wrap'))

      // scroll to anchor if there is one
      if (!isNumber(scrollTarget) && urlAnchor) {
        this.scrollToAnchor(urlAnchor)
      }
    })

    transitionOut()

    if (isNumber(scrollTarget)) {
      // window.scroll({
      //   top: scrollTarget
      // })
      window.scroll({
        top: scrollTarget,
        behavior: 'smooth'
      })
      setTimeout(reveal, 100)
    } else {
      setTimeout(reveal, 1500)
    }

    function doLoad (h) {
      changeState()
      html = h

      setTimeout(() => {
        reveal()
      }, 2000)
    }

    const params = {
      method: 'get',
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    }

    return window.fetch(url, params).then((response) => {
      return response.text()
    }).then(doLoad)
    // .catch((err) => {
    //   console.log(err.message)
    //   window.location = url
    //   // if (xhr.status === 404) {
    //   //   doLoad(xhr.responseText)
    //   // } else {
    //   //   window.location = url
    //   // }
    // })
  }
  scrollToAnchor (target, smooth = true) {
    if (typeof target !== 'number') {
      // assume a selector
      const targetEl = document.getElementById(target)
      target = targetEl.getBoundingClientRect().top + window.scrollY
    }

    window.scroll({
      top: target,
      behavior: smooth ? 'smooth' : 'auto'
    })
  }
  onStateChange (url) {

  }
  bind () {
    if (this.history) {
      window.addEventListener('popstate', e => {
        // check state to avoid handling Crapfari's pageload popstate event
        if (e.state && e.state.ns === this.ns) {
          const url = getCurrentUrl()
          this.load(url, null, e.state)
          this.onStateChange(url)
        }
      })

      // replace original state so original loaded page works
      window.history.replaceState({ns: this.ns}, null, getCurrentUrl())
    }
  }
}

function navigation (initContent, menu) {
  const ajax = new JamieMcLellanAjaxLoader(initContent, menu)

  // handle all link clicks here
  // TODO target specific links maybe?
  delegate('a', 'click', e => {
    const modifier = e.shiftKey || e.ctrlKey || e.metaKey
    const link = e.delegateTarget
    const local = isLocalLink(link)

    // ignore infinite scroll next links
    if (link.matches('.next-link')) {
      return
    }

    // hard pageload if on the 404 page
    if (document.body.classList.contains('p404')) {
      return
    }

    const contentExists = document.querySelector('#content').children.length

    if (!modifier && local) {
      ajax.load(link.getAttribute('href'))
      e.preventDefault()
    }
  })
}

export default navigation
