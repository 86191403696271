import register from '../util/init'
import {iosInnerHeight, isMobileSite, isLandscapeSite} from './constants'
import {carousel} from './featureCarousel'
import InfScr from './infscr'
import {checkVisible, moveCursor} from './tools'
import $ from 'jquery'
import Cookies from 'js-cookie'


function portfolioHome (container) {
  const html = document.documentElement
  const titleWrapEl = html.querySelector('.title-banner-wrap')
  const featuredWrap = container.querySelector('.featured-wrap .carousel-wrap')
  const carouselItems = featuredWrap.querySelectorAll('.carousel-item .image-wrap')
  const cursorEl = html.querySelector('#cursor')
  const splitTitleObj = window.splitTitle
  const firstLoad = Cookies.get('jamiemclellan-loaded') || false
  let carouselObj = {}
  let isMobile = isMobileSite()
  let isLandscape = isLandscapeSite()
  let projectTimer
  let blankSlide = false
  let ww = window.innerWidth
  let wh = iosInnerHeight()

  const featureWrapInit = () => {
    let galleryOpts = {wrapAround: true}
    if (isMobile) {
      galleryOpts['setGallerySize'] = false
      galleryOpts['adaptiveHeight'] = false
      galleryOpts['prevNextButtons'] = true
      galleryOpts['pageDots'] = false
    }
    carouselObj = carousel(featuredWrap, galleryOpts)

    carouselItems.forEach((imageEl) => {
      imageEl.addEventListener('mouseenter', (e) => {
        html.classList.add('state-carousel-hover')
      })
      imageEl.addEventListener('mouseleave', (e) => {
        html.classList.remove('state-carousel-hover')
      })
    })
    featuredWrap.addEventListener('mouseenter', (e) => {
      clearTimeout(projectTimer)
      html.classList.remove('state-cursor-visible')
      html.classList.remove('state-project-hover', 'state-cursor-link', 'state-project-hover-off', 'state-project-hover-on')
      html.classList.add('state-project-hover-on')
      html.classList.add('state-cursor-visible')

      projectTimer = setTimeout(() => {
        html.classList.remove('state-project-hover-on')
      }, 250)
    })
    featuredWrap.addEventListener('mouseleave', (e) => {
      clearTimeout(projectTimer)
      html.classList.add('state-project-hover-off')

      projectTimer = setTimeout(() => {
        html.classList.remove('state-cursor-visible')
        html.classList.remove('state-cursor-link', 'state-project-hover-off', 'state-project-hover-on', 'state-cursor-left', 'state-cursor-right')
      }, 250)
    })
    featuredWrap.addEventListener('mousemove', (e) => {
      let x = 100 / featuredWrap.offsetWidth * e.x

      if (blankSlide) {
        if (x > 50) {
          html.classList.add('state-cursor-right')
          html.classList.remove('state-cursor-left', 'state-cursor-link')
        } else {
          html.classList.add('state-cursor-left')
          html.classList.remove('state-cursor-right', 'state-cursor-link')
        }
      } else {
        if (x > 66.66) {
          html.classList.add('state-cursor-right')
          html.classList.remove('state-cursor-left', 'state-cursor-link')
        } else if (x < 33.33) {
          html.classList.add('state-cursor-left')
          html.classList.remove('state-cursor-right', 'state-cursor-link')
        } else {
          html.classList.add('state-cursor-link')
          html.classList.remove('state-cursor-left', 'state-cursor-right')
        }
      }
    })
    featuredWrap.querySelectorAll('.carousel-item .project-link').forEach((el) => {
      el.addEventListener('mouseenter', (e) => {
        html.classList.add('state-cursor-link')
      })
      el.addEventListener('mouseleave', (e) => {
        html.classList.remove('state-cursor-link')
      })
    })
    if (carouselObj.flkty) {
      carouselObj.flkty.on('select', () => {
        if (carouselObj.flkty.selectedElement.classList.contains('blank')) {
          blankSlide = true
        } else {
          blankSlide = false
        }
      })
      carouselObj.flkty.on('dragMove', (e) => {
        moveCursor(cursorEl, [e.x, e.y])
      })
      carouselObj.flkty.on('staticClick', (e) => {
        if (isMobile) return
        let x = 100 / featuredWrap.offsetWidth * e.x

        if (blankSlide) {
          if (x > 50) {
            carouselObj.flkty.next()
          } else {
            carouselObj.flkty.previous()
          }
        } else {
          if (x > 66.66) {
            carouselObj.flkty.next()
          } else if (x < 33.33) {
            carouselObj.flkty.previous()
          } else {
            carouselObj.flkty.selectedElement.querySelector('.project-link').click()
          }
        }
      })
    }
  }

  const resizeCarousel = (...args) => {
    const resizeItems = (h, limit) => {
      if (carouselItems) {
        if (isMobile) {
          carouselItems.forEach((el) => {
            const testEl = el
            let img
            if (testEl.classList.contains('has-mobile') && !isLandscape) {
              img = testEl.querySelector('.mobile-only .image img')
            } else {
              img = testEl.querySelector('.image img')
            }
            const imgW = parseInt(img.getAttribute('width'))
            const imgH = parseInt(img.getAttribute('height'))

            if (isLandscape) {
              el.style.width = `${imgW / imgH * (wh / 36 * 32)}px`
            } else {
              el.style.width = `${imgW / imgH * (ww / 36 * 32)}px`
            }
          })
        } else {
          const testEl = carouselItems[0]
          const img = testEl.querySelector('.image img')
          const w = parseInt(img.getAttribute('width')) / parseInt(img.getAttribute('height')) * h
          const newW = (w >= args[0] && limit) ? '100%' : `${w}px`

          carouselItems.forEach((el) => {
            el.style.width = newW
          })
        }
      }
      if (carouselObj.flkty) carouselObj.flkty.resize()
    }
    if (!isMobile) {
      resizeItems(args[1] - 140 * 2, true)
    } else {
      resizeItems(featuredWrap.offsetHeight, false)
    }
    setTimeout(() => {
      featuredWrap.parentNode.classList.add('initialised-size')
    }, isMobile ? 1750 : 0)
  }

  featureWrapInit()

  const titleLoadedInterval = setInterval(() => {
    if (html.classList.contains('state-banner-loaded')) {
      clearInterval(titleLoadedInterval)
      setTimeout(() => {
        splitTitleObj.transition()

        setTimeout(() => {
          html.classList.add('state-home-loaded', 'state-site-loaded')
        }, firstLoad ? 1500 : 0)
      }, 1750)
    }
  }, 30)

  Cookies.remove('jamiemclellan-loaded')

  return {
    resize: (...args) => {
      ww = args[0]
      wh = args[1]
      isMobile = isMobileSite()
      isLandscape = isLandscapeSite()

      setTimeout(() => {
        resizeCarousel(...args)
        splitTitleObj.resize(...args)
      }, 25)
    },
    teardown: () => {
      clearInterval(titleLoadedInterval)
      html.classList.remove('state-home-loaded')
    }
  }
}
register('.portfolio-home', portfolioHome)
