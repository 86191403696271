import {addHandler} from '../util/scroll_resize'


export const PAGE_TRANSITION = 750
export const CAROUSEL_TRANSITION = 300
export const MAX_PHONE = 767
export const MAX_MOBILE = 1024
export const iosInnerHeight = require('ios-inner-height');

export let WIN_WIDTH = window.innerWidth
export let WIN_HEIGHT = iosInnerHeight()

addHandler({
  resize: function (...args) {
    WIN_WIDTH = args[0]
    WIN_HEIGHT = args[1]
  }
})

export function isMobileSite () {
  return WIN_WIDTH <= MAX_MOBILE
}

export function isLandscapeSite() {
  return WIN_WIDTH > WIN_HEIGHT
}
